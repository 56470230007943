import * as types from '../../constants';
import { SetThemeType } from '../actions/themeActions';
import { THEMES } from '../../constants';

export type ThemeInitialStateType = {
  currentTheme: string;
};

const savedTheme = localStorage.getItem('@ssaAdmin:theme');

const initialState = {
  currentTheme: savedTheme ? savedTheme : THEMES.DEFAULT,
};

export default function reducer(
  state = initialState,
  actions: ActionTypes
): ThemeInitialStateType {
  switch (actions.type) {
    case types.THEME_SET:
      localStorage.setItem('@ssaAdmin:theme', actions.payload);
      console.log(actions.payload);
      return {
        ...state,
        currentTheme: actions.payload,
      };

    default:
      return state;
  }
}

type ActionTypes = SetThemeType;
