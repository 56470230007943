import { Reducer } from 'redux';
import * as types from '../../constants';

export interface IUser {
  email: string;
  nome: string;
  login: string;
  idRepresentante: number;
  nomeRepresentante: string;
}

export interface IAuth {
  user?: IUser;
  loggingIn?: boolean;
}

const savedUser = localStorage.getItem('@ssaAdmin:user');

let initialUser = {} as IUser;
let initialLoggingIn = false;

if (savedUser) {
  initialUser = JSON.parse(savedUser);
  initialLoggingIn = true;
}

const INITIAL_STATE: IAuth = {
  user: initialUser,
  loggingIn: initialLoggingIn,
};

const auth: Reducer<IAuth> = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS: {
      const { user } = actions.payload;
      return {
        user,
        loggingIn: true,
      };
    }

    case types.AUTH_SIGN_OUT:
      return {};

    default:
      return state;
  }
};

export default auth;
