import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('@ssaAdmin:user');
      localStorage.removeItem('@ssaAdmin:token');

      api.defaults.headers.common['Authorization'] = null;

      window.location.href = '/auth/sign-in';
    }
    return Promise.reject(error);
  }
);

export default api;
