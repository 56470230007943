import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStateType } from '../redux/reducers';
import { setTheme } from '../redux/actions/themeActions';
import { THEMES } from '../constants';

import { IconButton, Tooltip } from '@material-ui/core';

import { Sun, Moon } from 'react-feather';

function ThemeSelector() {
  const dispatch = useDispatch();
  const theme = useSelector((state: AppStateType) => state.themeReducer);

  const handleChange = () => {
    const selectedTheme =
      theme.currentTheme === THEMES.DARK ? THEMES.DEFAULT : THEMES.DARK;
    dispatch(setTheme(selectedTheme));
  };

  return (
    <Tooltip
      title={theme.currentTheme === THEMES.DARK ? 'Modo claro' : 'Modo escuro'}
    >
      <IconButton color="inherit" onClick={handleChange}>
        {theme.currentTheme === THEMES.DARK && <Sun />}
        {theme.currentTheme !== THEMES.DARK && <Moon />}
      </IconButton>
    </Tooltip>
  );
}

export default ThemeSelector;
