import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';

interface User {
  nome: string;
  email: string;
  login: string;
  idRepresentante: number;
  nomeRepresentante: string;
}

interface AuthState {
  user: User;
  token: string;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthContextData {
  signed: boolean;
  user: User;
  signIn(credentials: SignInCredencials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@ssaAdmin:user');
    const token = localStorage.getItem('@ssaAdmin:token');

    if (user && token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      return { user: JSON.parse(user), token };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const response = await api.post('/token', {
        email,
        senha: password,
      });

      const { token, user } = response.data;

      if (user && token) {
        localStorage.setItem('@ssaAdmin:user', JSON.stringify(user));
        localStorage.setItem('@ssaAdmin:token', token);

        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        setData({ token, user });
      }
    } catch (err) {
      throw new Error(err.response.data);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ssaAdmin:user');
    localStorage.removeItem('@ssaAdmin:token');

    api.defaults.headers.common['Authorization'] = null;

    setData({} as AuthState);

    window.location.href = '/auth/sign-in';
  }, []);

  return (
    <AuthContext.Provider
      value={{ signed: !!data.user, user: data.user, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
