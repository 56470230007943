import * as React from 'react';
import styled, { withTheme } from 'styled-components/macro';
// import { darken } from "polished";

import {
  Avatar as MuiAvatar,
  // Box,
  Grid,
  Hidden,
  // InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Typography,
  Toolbar,
} from '@material-ui/core';

import { Menu as MenuIcon } from '@material-ui/icons';

// import NotificationsDropdown from "./NotificationsDropdown";
// import MessagesDropdown from "./MessagesDropdown";
// import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from './UserDropdown';
import ThemeSelector from './ThemeSelector';
// import { useSelector } from "react-redux";
// import { AppStateType } from "../redux/reducers";
import { useAuth } from '../contexts/auth';

const AppBar = styled(MuiAppBar)`
  padding-left: 7px;
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${(props) => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;

//   &:hover {
//     background-color: ${(props) => darken(0.05, props.theme.header.background)};
//   }

//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)}px;
//     padding-right: ${(props) => props.theme.spacing(2.5)}px;
//     padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
//     padding-left: ${(props) => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

const UserText = styled(Typography)`
  // color: ${(props) => props.theme.header.search.color};
  // color: black;
  // font-size: 1.1rem;
  // font-weight: bold;
  display: block;
  padding: 1px;
`;

const UserSubText = styled(Typography)`
  //color: ${(props) => props.theme.header.search.color};
  //font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const Avatar = styled(MuiAvatar)`
  avatar: {
     border: 2px solid red;
     boxSizing: 'content-box';
`;

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  //const auth = useSelector((state: AppStateType) => state.authReducer);
  const { user } = useAuth();
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={4}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {/* <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder="Procurar" />
            </Search>
          </Grid> */}
            <Grid item>
              <Avatar alt={user?.nome} src="/static/img/avatars/default.jpeg" />
            </Grid>
            <Grid item>
              <UserText variant="h5">{user?.nome}</UserText>
              <UserSubText variant="subtitle2" color="textSecondary">
                {user?.nomeRepresentante}
              </UserSubText>
            </Grid>
            <Grid item xs />
            <Grid item>
              <ThemeSelector />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
